import { useEffect, useState } from "react";
import {
  VStack,
  Button,
  Text,
  HStack,
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  TableCaption,
  Link
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { networkParams } from "./networks";
import { toHex, truncateAddress } from "./utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./providerOptions";

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

export default function Home() {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      setAgreement();
      if (accounts) setAccount(accounts[0]);
      setChainId(network.name);
    } catch (error) {
      setError(error);
    }
  };

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  const setAgreement = () => {
    const msg = "I agree to Carbon Investment Agreement v1";
    setMessage(msg);
  };

  const signMessage = async () => {
    if (!library) return;

    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account]
      });
      setSignedMessage(message);
      setSignature(signature);

      fetch(
        "https://rv0pzcojf6.execute-api.ap-southeast-1.amazonaws.com/default/customer_signup_carbon",
        {
          crossDomain: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            addr: { account },
            signed_data: { signature },
            network_id: { chainId }
          })
        }
      ).then((response) => response.json());
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature]
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setAgreement();
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  return (
    <>
      <VStack justifyContent="center" alignItems="center" h="100vh">
        <HStack marginBottom="10px">
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="300"
          >
            Invest with
          </Text>
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="600"
            sx={{
              background: "linear-gradient(90deg, #000000 0%, #eeeeee 70.35%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
          >
            Carbon
          </Text>
        </HStack>
        <HStack>
          <Text margin="0" fontWeight="50" fontSize="20px" marginBottom="20px">
            <b>Carbon</b> is a Systematic Strategy in Crypto Currencies with a
            goal of attractive absolute return.
          </Text>
        </HStack>
        <HStack>
          {!account ? (
            <Button onClick={connectWallet}>Connect Your Wallet</Button>
          ) : (
            <Button onClick={disconnect}>Disconnect</Button>
          )}
        </HStack>
        <VStack justifyContent="center" alignItems="center" padding="10px 0">
          <HStack>
            <Text>{`Connection Status: `}</Text>
            {account ? (
              <CheckCircleIcon color="green" />
            ) : (
              <WarningIcon color="#cd5700" />
            )}
          </HStack>

          <Tooltip label={account} placement="right">
            <Text>
              {`Address: ${account}`} @{" "}
              {`Network ID: ${chainId ? chainId : "No Network"}`}
            </Text>
          </Tooltip>
        </VStack>

        {account && (
          <HStack justifyContent="flex-start" alignItems="flex-start">
            <Box
              width="max"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="10px"
            >
              <VStack>
                <Table variant="simple">
                  <TableCaption>Carbon Investment Agreement v1</TableCaption>
                  <Tbody>
                    <Tr>
                      <Td>
                        <b>Fund Name</b>
                      </Td>
                      <Td>
                        ProperSubset Carbon Fund II (The
                        "Fund")
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Manager</b>
                      </Td>
                      <Td>
                        <Link href="mailto:founders@propersubset.xyz" color="blue.500">
                          ProperSubset (The "General Partners")
                        </Link>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Target Amount</b>
                      </Td>
                      <Td>USDT 500,000</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Fund Base Currency</b>
                      </Td>
                      <Td>USDC</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Closing Date</b>
                      </Td>
                      <Td>3/18/2022</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Subsequent Closings</b>
                      </Td>
                      <Td>
                        Additional closings may occur at the discretion of the
                        General Partner
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Term of the Fund</b>
                      </Td>
                      <Td>
                        <p>
                          180 calendar days from the date where the principals are deployed to trade by the Team
                        </p>
                        <p>
                          (the Client shall receive email confirmation from the Team on the date of deployment),
                        </p>
                        <p>
                          with extensions at the discretion of the General Partner.
                        </p>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Lockup Period</b>
                      </Td>
                      <Td>First 90 calendar days</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Investment Policy</b>
                      </Td>
                      <Td>
                        <p>
                          The objective of the Fund is to provide attractive
                          risk adjusted return
                        </p>
                        <p>
                          by investing in a diversified portfolio of crypto
                          currencies
                        </p>
                        <p>
                          (including spot currency, futures and options
                          contracts)
                        </p>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Management Fee</b>
                      </Td>
                      <Td>Waived for early adoptors</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Performance Fee</b>
                      </Td>
                      <Td>
                        <p>
                          20% of the returns (for the portion of return &lt;
                          20%)
                        </p>
                        <p>
                          30% of the returns (for the portion of return &gt;=
                          20%)
                        </p>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Redumptions</b>
                      </Td>
                      <Td>
                        <p>
                          Redemptions are allowed only once during the term of
                          the fund.
                        </p>
                        <p>
                          If the redemption happens within the lock up period,
                          then a 2% fee on the initial invested capital is
                          charged.
                        </p>
                        <p>
                          There is no fee for redemption after the lock up
                          period.
                        </p>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Reporting and Disclosure</b>
                      </Td>
                      <Td>
                        Investors are entitled to get regular updates (at least
                        monthly) of fund performance
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Fund Transfer</b>
                      </Td>
                      <Td>We accept only USDC wires. Please check the <Link href="https://docs.google.com/document/u/2/d/e/2PACX-1vSn2_mhZ2UItOsA2DEJkNdkLs-jarSpW8ivYDTtNxsWCl0mk8Fa4lNzo7-Whd466uA3z0Xq-QIV7Xxx/pub" color="blue.500">deposit instructions</Link> for details</Td>
                    </Tr>
                  </Tbody>
                </Table>
                <Button onClick={signMessage} colorScheme="blackAlpha">
                  Sign Agreement
                </Button>
              </VStack>
            </Box>
          </HStack>
        )}
        {signature ? (
          <Tooltip label={signature} placement="bottom">
            <Text color="green.500">
              <p>
                Welcome to the fund! Your Wallet address has been recorded and
                will be used for future sign-in
              </p>
              <p>{`Signature: ${truncateAddress(signature)}`}</p>
            </Text>
          </Tooltip>
        ) : null}
        {error ? (
          <Text color="red.500">
            <p>
              Agreement sign failed, please try to re-connect your Wallet and
              try again
            </p>
            {error.message}
          </Text>
        ) : null}
      </VStack>
    </>
  );
}
